module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WBXW2G2","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"healthphy","lang":"*","accessToken":"MC5YeTlxdUJJQUFDQUFGZ2pR.77-977-9Ue-_ve-_vRFKW3kOau-_ve-_vQVn77-9Mu-_ve-_vUPvv73vv73vv73vv71nNy99UO-_vTIn","releaseID":"master","schemas":{"Main":{"page_title":{"type":"StructuredText"}}},"prismicToolbar":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HealthPhy","short_name":"HealthPhy","start_url":"/","background_color":"#495FD7","theme_color":"#495FD7","display":"standalone","icon":"src/assets/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"ea5c1e5a811e49b946e419adbe6e1c96"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
