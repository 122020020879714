// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-companies-jsx": () => import("./../../../src/templates/companies.jsx" /* webpackChunkName: "component---src-templates-companies-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-press-jsx": () => import("./../../../src/templates/press.jsx" /* webpackChunkName: "component---src-templates-press-jsx" */),
  "component---src-templates-providers-jsx": () => import("./../../../src/templates/providers.jsx" /* webpackChunkName: "component---src-templates-providers-jsx" */)
}

